import styled from "styled-components";
import Text from "../Text/Text";
import Message from "../Message/Message";
import InputChat from "../InputChat/InputChat";
import { useContext, useEffect, useRef, useState } from "react";
import { MessageModel } from "../InputChat/types";
import { CHAT_STEPS, ChatProps, User } from "./types";
import useSocket from "../../hooks/socket/useSocket";
import usePostUser from "../../hooks/user/usePostUser";
import { UserDTO } from "../../../domain/models/User";
import {
  Attachment,
  AttachmentType,
  OmuniMessage,
} from "../../hooks/socket/types";
import Icon from "../../utils/Icons";
import Gallery from "../Gallery/Gallery";
import { FontSize, FontWeight } from "../../utils/Fonts";
import { Direction } from "../../utils/Direction";
import { calculateContrastYIQ } from "../../utils/Others";
import { ConfigurationContext } from "../../context/configuration/configurationContext";
import MessageButtons from "../MessageButtons/MessageButtons";
import RegisterForm from "../RegisterForm/RegisterForm";

const Container = styled.div<{
  isOpen: boolean;
  bottom: string | null;
  right: string | null;
  dataFrameBottom: string | null;
  dataFrameRight: string | null;
  maxHeight: string | null;
}>`
  z-index: 9999999;
  position: fixed;
  display: flex;
  background-color: #ffffff;
  flex-direction: column;
  right: ${(props) =>
    props.dataFrameRight
      ? `${props.dataFrameRight}px`
      : props.right && parseInt(props.right) > 7
      ? `${props.right}px`
      : "20px"};
  bottom: ${(props) =>
    props.dataFrameBottom
      ? `${props.dataFrameBottom}px`
      : props.bottom && parseInt(props.bottom) > 7
      ? `${parseInt(props.bottom) + 64}px`
      : "84px"};
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  height: ${(props) =>
    props.isOpen
      ? props.maxHeight
        ? props.maxHeight
        : `min(704px, 100% - ${
            props.bottom && parseInt(props.bottom) > 7
              ? 104 + parseInt(props.bottom)
              : 104
          }px)`
      : "0"};
  width: ${(props) => (props.isOpen ? "min(400px, calc(100% - 40px))" : "0")};
  max-height: ${(props) => props.maxHeight || "704px"};
  box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 40px;
  border-radius: 16px;
  overflow: hidden;
  opacity: 1;
  transform-origin: right bottom;
  transition: width 200ms ease 0s, height 200ms ease 0s,
    max-height 200ms ease 0s, transform 300ms cubic-bezier(0, 1.2, 1, 1) 0s,
    opacity 83ms ease-out 0s;
  pointer-events: all;
`;

const Header = styled.div<{ background?: string }>`
  padding: 1rem;
  height: 3.5rem;
  background-color: ${(props) => props.background ?? "#233348"};
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const MessageContainer = styled.div`
  background-color: #ffffff;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f2f2f2;
    border-radius: 20px;
    border: 3px solid #ffffff;
  }
`;

const IconWrapper = styled.div`
  width: 52px;
  height: 52px;

  & > img {
    border-radius: 50%;
    width: 52px;
    height: 52px;
    object-fit: cover;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
`;

const ActiveWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const Circle = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  background-color: #00c900;
  border-radius: 50%;
`;

const Powered = styled.div`
  background-color: #ffffff;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  gap: 0.25rem;
  z-index: 9999999;

  & > img {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const DEFAULT_USER = {
  name: undefined,
  email: undefined,
  contactId: undefined,
};

const USER_DATA_LOCAL_STORAGE = "omuniLoggedUser";

const MESSAGE_LIST_LOCAL_STORAGE = "MessageList";

const USERNAME = "Omuni WebChat";

const MESSAGE_COMPLETE_STEP =
  "¡Gracias por completar tus datos! ¿En qué puedo ayudarte hoy? 😊";

export default function Chat(chatProps: ChatProps) {
  const {
    isOpen,
    connectionId,
    bottom,
    right,
    dataFrameBottom,
    dataFrameRight,
    handleNewMessageUnread,
    handleResetMessagesUnread,
  } = chatProps;

  const [conversationState, setConversationState] = useState<CHAT_STEPS>(
    CHAT_STEPS.STEP_INITIAL
  );

  const [userData, setUserData] = useState<User>(DEFAULT_USER);

  const [messageList, setMessageList] = useState<MessageModel[] | null>(null);

  const MessageContainerRef = useRef<HTMLDivElement | null>(null);

  const socket = useSocket(connectionId, userData.contactId);

  const createUser = usePostUser();

  const [attachments, setAttachments] = useState<Attachment[]>();

  const [selectedAttachment, setSelectedAttachment] = useState<Attachment>();

  const [isOpenGallery, setIsOpenGallery] = useState(false);

  const configuration = useContext(ConfigurationContext);
  const messageButtons =
    messageList && messageList[messageList.length - 1]?.buttons;

  const getShowMessages = (messageList: MessageModel[] | null) => {
    if (!messageList) return false;

    const lastMessagesButtons =
      messageList[messageList.length - 1]?.buttons ?? [];

    return !!(
      lastMessagesButtons.length > 0 &&
      messageList[messageList.length - 1].userType === Direction.BOT
    );
  };

  const showMessageButtons = getShowMessages(messageList);

  const handleAttachments = (attachments?: Attachment[]) => {
    setAttachments(attachments);
    setIsOpenGallery(true);
  };

  const handleReplyWithButton = (newMessage: MessageModel) => {
    newMessageStorage(newMessage);
  };

  const handleSelectedAttachment = (attachment: Attachment) => {
    setIsOpenGallery(true);
    setSelectedAttachment(attachment);
  };

  const handleCloseGallery = () => {
    setIsOpenGallery(false);
    setSelectedAttachment(undefined);
  };

  const awaitResponseBot: boolean =
    conversationState === CHAT_STEPS.STEP_REGISTERING_USER;

  const handleRegisterUser = (name: string, email: string) => {
    setUserData((oldValues) => ({
      ...oldValues,
      name,
      email,
    }));
  };

  // Store message in localstorage and send it to Omuni
  const newMessageStorage = async (newMessage: MessageModel) => {
    const formattedMessage = {
      ...newMessage,
      writingMachine: newMessage.userType === Direction.BOT ? true : false,
    };

    setMessageList((oldMessageList) => {
      if (oldMessageList) {
        return [...oldMessageList, formattedMessage];
      }

      return [formattedMessage];
    });

    const storedMessages: string | null = localStorage.getItem(
      MESSAGE_LIST_LOCAL_STORAGE
    );
    const parsedMessages: MessageModel[] = storedMessages
      ? JSON.parse(storedMessages)
      : [];

    localStorage.setItem(
      MESSAGE_LIST_LOCAL_STORAGE,
      JSON.stringify([...parsedMessages, newMessage])
    );

    if (conversationState === CHAT_STEPS.ENABLE_COMMUNICATION) {
      handleSendMessage(newMessage);
    }
  };

  //Receive message from Omuni
  const receivedMessageStorage = async (newMessage: OmuniMessage) => {
    const formattedMessage: MessageModel = {
      text: newMessage.message,
      userType: Direction.BOT,
      attachments: newMessage.attachments ? [...newMessage.attachments] : [],
      buttons: newMessage.buttons,
      createdAt: newMessage.timestamp,
    };

    setMessageList((oldMessageList) => {
      const lastMessage =
        oldMessageList && oldMessageList[oldMessageList.length - 1];

      if (
        oldMessageList &&
        lastMessage?.groupMultimedia &&
        newMessage.attachments?.some(
          (attachment) =>
            attachment.type === AttachmentType.IMAGE ||
            attachment.type === AttachmentType.VIDEO
        )
      ) {
        lastMessage.groupMultimedia.push(...newMessage.attachments);
        const newMessageList: MessageModel[] = [
          ...oldMessageList.slice(0, -1),
          lastMessage,
        ];

        localStorage.setItem(
          MESSAGE_LIST_LOCAL_STORAGE,
          JSON.stringify(newMessageList)
        );

        return newMessageList;
      }

      // Rest of your existing logic...

      if (oldMessageList) {
        const newMessageList = [...oldMessageList, formattedMessage];

        localStorage.setItem(
          MESSAGE_LIST_LOCAL_STORAGE,
          JSON.stringify(newMessageList)
        );

        return newMessageList;
      }
      const newMessageList = [formattedMessage];

      localStorage.setItem(
        MESSAGE_LIST_LOCAL_STORAGE,
        JSON.stringify(newMessageList)
      );

      return [formattedMessage];
    });

    scrollToBottomSmooth();
  };

  // Format the message and send to Omuni
  const handleSendMessage = (message: MessageModel) => {
    const formattedMessage = {
      message: message.text,
      contactId: userData.contactId,
      messageId: "123lk4-n90a-sfasj-lfhas-kjf",
      displayName: userData.name,
      email: userData.email,
      extraData: userData.extraInfo,
      attachments: message.attachments,
      createdAt: Date.now(),
    };
    socket?.emit("message", formattedMessage);
    scrollToBottomSmooth();
  };

  /**
   * Processes the missing register step in the chat conversation.
   * If the conversation state is not in the STEP_REGISTERING_USER step, the function returns early.
   * If the configuration subtitle is not defined, it uses the MESSAGE_COMPLETE_STEP constant as the default value.
   * Creates a bot response message with the text, userType, attachments, writingMachine, and createdAt properties.
   * Sets the conversation state to ENABLE_COMMUNICATION after a delay of 1000 milliseconds.
   * Calls the newMessageStorage function with the bot response message.
   */
  const processMissingRegisterStep = () => {
    if (conversationState !== CHAT_STEPS.STEP_REGISTERING_USER) return;

    const botResponse: MessageModel = {
      text: configuration?.subtitle ?? MESSAGE_COMPLETE_STEP,
      userType: Direction.BOT,
      attachments: [],
      writingMachine: true,
      createdAt: Date.now(),
    };

    setTimeout(() => {
      setConversationState(CHAT_STEPS.ENABLE_COMMUNICATION);
      newMessageStorage(botResponse);
    }, 1000);
  };

  const scrollToBottomSmooth = () => {
    if (!MessageContainerRef.current) return;

    MessageContainerRef.current.scrollTo({
      top: MessageContainerRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

  // Reset unread messages
  useEffect(() => {
    if (!isOpen) return;
    handleResetMessagesUnread();
    scrollToBottomSmooth();
  }, [isOpen]);

  // Start conversation or load data to continue the conversation
  useEffect(() => {
    // User information exist in local stosage
    const storedUserData: string | null = localStorage.getItem(
      USER_DATA_LOCAL_STORAGE
    );

    // Messages exist in local stosage
    const storedMessages: string | null = localStorage.getItem(
      MESSAGE_LIST_LOCAL_STORAGE
    );

    const messages = storedMessages ? JSON.parse(storedMessages)?.length : 0;

    // If we have the necessary user data but no old messages
    if (storedUserData && (!storedMessages || messages === 0)) {
      setConversationState(CHAT_STEPS.ENABLE_COMMUNICATION);

      setUserData(JSON.parse(storedUserData));
      return;
    }

    // If we have the necessary user data and also the old messages
    if (storedUserData && storedMessages) {
      const parsedStoredUserData = JSON.parse(storedUserData);

      if (parsedStoredUserData.name && parsedStoredUserData.email) {
        setConversationState(CHAT_STEPS.ENABLE_COMMUNICATION);
        setUserData(JSON.parse(storedUserData));

        const messages = JSON.parse(storedMessages);

        setMessageList(messages);
        scrollToBottomSmooth();

        return;
      }
    }

    if (storedUserData) {
      localStorage.removeItem(USER_DATA_LOCAL_STORAGE);
    }

    if (userData) {
      setUserData(DEFAULT_USER);
      setMessageList(null);
    }

    if (storedMessages) {
      localStorage.removeItem(MESSAGE_LIST_LOCAL_STORAGE);
    }

    if (messageList) {
      setMessageList(null);
    }
  }, []);

  // Save contact id after creating user
  useEffect(() => {
    if (!createUser.data) return;

    const data: UserDTO = createUser.data;
    const updatedUser = { ...userData, contactId: data.user._id };

    setUserData(updatedUser);

    localStorage.setItem(USER_DATA_LOCAL_STORAGE, JSON.stringify(updatedUser));
    setConversationState(CHAT_STEPS.ENABLE_COMMUNICATION);
  }, [createUser.data]);

  // Create a user once the first name, last name and email address have been completed
  useEffect(() => {
    // Validate if already have a contact id
    if (userData.contactId) return;

    if (!userData.name || !userData.email) return;

    const data = {
      displayName: userData.name,
      email: userData.email,
    };

    createUser.post(data);
  }, [userData]);

  // Local bot simulation
  useEffect(() => {
    processMissingRegisterStep();

    if (
      conversationState === CHAT_STEPS.STEP_AWAIT_RESPONSE_CLIENT &&
      userData.name &&
      userData.email
    ) {
      setConversationState(CHAT_STEPS.ENABLE_COMMUNICATION);
    }

    scrollToBottomSmooth();
  }, [messageList]);

  useEffect(() => {
    if (
      conversationState === CHAT_STEPS.ENABLE_COMMUNICATION &&
      (!messageList || messageList.length === 0)
    ) {
      const botFirstResponse: MessageModel = {
        text: `Hola ${userData.name}!`,
        userType: Direction.BOT,
        attachments: [{ type: AttachmentType.DEFAULT, payload: { url: "" } }],
        createdAt: Date.now(),
      };

      setTimeout(() => {
        newMessageStorage(botFirstResponse);
      }, 1000);

      const botResponse: MessageModel = {
        text: configuration?.subtitle ?? MESSAGE_COMPLETE_STEP,
        userType: Direction.BOT,
        attachments: [{ type: AttachmentType.DEFAULT, payload: { url: "" } }],
        createdAt: Date.now(),
      };

      setTimeout(() => {
        newMessageStorage(botResponse);
      }, 2000);

      return;
    }
  }, [conversationState]);

  // Listening new message from omuni
  useEffect(() => {
    if (!socket) return;

    socket.on("message", (comunication: OmuniMessage) => {
      if (!isOpen) {
        handleNewMessageUnread();
      }

      receivedMessageStorage(comunication);
    });

    // Eliminates the event when the component is unmounted
    return () => {
      socket.off("message");
    };
  }, [socket, isOpen, handleNewMessageUnread, receivedMessageStorage]);

  /**
   * Checks if the next message in the message list is from a different user than the current message.
   *
   * @param {Direction} currentDirection - The direction of the current message.
   * @param {number} currentIndex - The index of the current message in the message list.
   * @return {boolean} Returns true if the next message is from a different user, false otherwise.
   */
  const nextMeessageIsDifferentUser = (
    currentDirection: Direction,
    currentIndex: number
  ) => {
    if (messageList?.length === 0) return false;

    const nextMessage = messageList?.[currentIndex + 1];

    return nextMessage?.userType !== currentDirection;
  };

  return (
    <>
      <Container
        right={right}
        bottom={bottom}
        isOpen={isOpen}
        dataFrameBottom={dataFrameBottom}
        dataFrameRight={dataFrameRight}
        maxHeight={chatProps.maxHeight}
      >
        <Header background={configuration?.primaryColor}>
          <IconWrapper>
            <img
              src={
                configuration?.titleAvatar || configuration?.titleAvatar !== ""
                  ? configuration?.titleAvatar
                  : Icon.BOT_V2
              }
            />
          </IconWrapper>
          <TitleWrapper>
            <Text
              fontColor={calculateContrastYIQ(configuration?.primaryColor)}
              value={configuration?.title ?? USERNAME}
              fontWeight={FontWeight.FONT_BOLD}
              fontSize={FontSize.TEXT_XL}
            />

            <ActiveWrapper>
              <Circle />
              <Text
                value="Activo"
                fontSize={FontSize.TEXT_SM}
                fontColor={calculateContrastYIQ(
                  configuration?.primaryColor,
                  0.7
                )}
              />
            </ActiveWrapper>
          </TitleWrapper>
        </Header>
        {CHAT_STEPS.STEP_INITIAL === conversationState && (
          <RegisterForm
            handleRegister={handleRegisterUser}
            backgroundButton={configuration?.primaryColor}
          />
        )}
        {CHAT_STEPS.ENABLE_COMMUNICATION === conversationState && (
          <>
            <MessageContainer ref={MessageContainerRef}>
              {messageList?.map((message, index) => (
                <Message
                  key={index}
                  index={index}
                  {...message}
                  conversationState={conversationState}
                  allMessages={messageList}
                  textColor={configuration?.primaryColor}
                  messageColor={configuration?.primaryColor}
                  nextIsDifferentUser={nextMeessageIsDifferentUser(
                    message.userType,
                    index
                  )}
                  handleAttachments={handleAttachments}
                  handleSelectedAttachment={handleSelectedAttachment}
                />
              ))}
              <MessageButtons
                buttons={messageButtons ?? null}
                showButtons={showMessageButtons}
                configuration={configuration}
                handleReplyWithButton={handleReplyWithButton}
              />
            </MessageContainer>
            <InputChat
              isDisabled={awaitResponseBot || showMessageButtons}
              isMomentEmailValidate={false}
              arrowColor={configuration?.primaryColor}
              handleSendMessage={newMessageStorage}
            />
            <Powered>
              <Text
                fontColor="#a9a9a9"
                fontSize={FontSize.TEXT_XS}
                value="Powered by"
              />
              <Text
                fontColor="#a9a9a9"
                fontSize={FontSize.TEXT_XS}
                value="Omuni"
              />
            </Powered>
          </>
        )}
      </Container>

      <Gallery
        isOpen={isOpenGallery}
        handleCloseGallery={handleCloseGallery}
        attachments={attachments}
        selected={selectedAttachment}
      />
    </>
  );
}
