import ReactDOM from "react-dom";
import App from "./components/App";
import { ThemeProvider } from "styled-components";
import theme from "./theme/theme";
import root from "react-shadow/styled-components";
import './styles.css';
import '../i18n';

const rootElement = document.getElementById("OmuniWebChat");

const connectionId = rootElement?.getAttribute("data-connection-id") ?? null;

const bottom = rootElement?.getAttribute("data-bottom") ?? null;

const right = rootElement?.getAttribute("data-right") ?? null;

const openDelay = rootElement?.getAttribute("data-open-delay") ?? null;

const dataFrameBottom = rootElement?.getAttribute("data-frame-bottom") ?? null;

const dataFrameRight = rootElement?.getAttribute("data-frame-right") ?? null;

const dataInitialOpenChat = rootElement?.hasAttribute("data-initial-open-chat")
  ? rootElement?.getAttribute("data-initial-open-chat") === "true"
  : null;

const removeBubbleFixedSize = rootElement?.getAttribute(
  "data-remove-bubble-fixed-size"
)
  ? rootElement?.getAttribute("data-remove-bubble-fixed-size") === "true"
  : null;

const maxChatHeight = rootElement?.getAttribute("data-max-chat-height") ?? null;

ReactDOM.render(
  <root.div>
    <ThemeProvider theme={theme}>
      <App
        connectionId={connectionId}
        right={right}
        bottom={bottom}
        openDelay={openDelay}
        dataFrameBottom={dataFrameBottom}
        dataFrameRight={dataFrameRight}
        dataInitialOpenChat={dataInitialOpenChat}
        removeBubbleFixedSize={removeBubbleFixedSize}
        maxChatHeight={maxChatHeight}
      />
    </ThemeProvider>
  </root.div>,
  rootElement
);
