import { Configuration } from "../../../domain/adapters/configuration";
export interface ChatProps {
  isOpen: boolean;
  connectionId: string | null;
  bottom: string | null;
  right: string | null;
  dataFrameBottom: string | null;
  dataFrameRight: string | null;
  maxHeight: string | null;
  handleNewMessageUnread: () => void;
  handleResetMessagesUnread: () => void;
}

export interface MessageButton{
  text: string;
};

interface ExtraInfoItem {
  key: string;
  value: string
};

export interface User {
  name?: string;
  // lastName?: string;
  email?: string;
  contactId?: string;
  extraInfo?: ExtraInfoItem[]
}

/**
 * Enumeration that defines the steps in the chat communication flow.
 * @enum {number}
 */
export enum CHAT_STEPS {
  /**
   * Initial step: the beginning of communication.
   */
  STEP_INITIAL,

  /**
   * Awaiting a client response when no user data is available.
   */
  STEP_AWAIT_RESPONSE_CLIENT, 

  /**
   * Awaiting the user to provide their contact information.
   */
  STEP_REGISTERING_USER,
 

  /**
   * Communication enabled after finding or creating a user with contact information.
   */
  ENABLE_COMMUNICATION,
}
