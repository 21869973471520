import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import esTranslation from '../public/locales/es/translation.json';
import enTranslation from '../public/locales/en/translation.json';
import esDate from '../public/locales/es/date.json';
import enDate from '../public/locales/en/date.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      es: {
        translation: esTranslation,
        date: esDate
      },
      en: {
        translation: enTranslation,
        date: enDate
      }
    },
    lng: 'es', // idioma por defecto
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
