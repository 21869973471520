export interface RegisterFormProps {
  backgroundButton?: string;
  handleRegister: (nameComplete: string, email: string) => void;
}

export enum REGISTER_FORM_TRANSLATIONS {
  FORM_TITLE = "form_title",
  FULL_NAME = "full_name",
  FULL_NAME_PLACEHOLDER = "full_name_placeholder",
  EMAIL = "email",
  EMAIL_PLACEHOLDER = "email_placeholder",
  START_BUTTON = "start_button",
}
  