import { useEffect, useState } from "react";
import { INPUT_CHAT_TRANSLATIONS, InputChatProps, MessageModel } from "./types";
import { Attachment } from "../../hooks/socket/types";
import SendButton from "../SendButton/SendButton";
import { Direction } from "../../utils/Direction";
import {
  ButtonsWrapper,
  Container,
  IconContainer,
  IconsWrapper,
  InputWithIcons,
  InputsWrapper,
} from "./styled";
import UploadFile from "../Upload/Upload";
import TextArea from "../TextArea/TextArea";
import FilesToSendPreview from "./FilesToSendPreview/FilesToSendPreview";
import { FileUploaded } from "../../../domain/models/FileUploaded";
import { useTranslation } from "react-i18next";

const DEFAULT_EMPTY_MESSAGE = "";

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export default function InputChat(inputChatProps: InputChatProps) {
  const { handleSendMessage, arrowColor, isMomentEmailValidate, isDisabled } =
    inputChatProps;

  const [isInputEnable, setIsInputEnable] = useState(false);

  const { t: translation } = useTranslation();

  const [isFocused, setIsFocused] = useState(false);

  const [message, setMessage] = useState<string>(DEFAULT_EMPTY_MESSAGE);

  const [fileList, setFileList] = useState<FileUploaded[]>([]);

  const onHandleNewMessage =()=>{
    if(!isInputEnable || isDisabled) return;
    if(fileList.length > 0){
      const attachments = fileList.map((f) => f.attachment );
      handleMessage(attachments);
      return;
    }
    handleMessage();
  }

  const handleMessage = (attachments?: Attachment[]) => {

    setMessage((sendMessage) => {
      const newMessage: MessageModel = {
        text: sendMessage,
        userType: Direction.CLIENT,
        createdAt: Date.now(),
      };

      if (attachments) {
        newMessage.attachments = attachments;
      }

      handleSendMessage(newMessage);

      setFileList([]);

      return DEFAULT_EMPTY_MESSAGE;
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      onHandleNewMessage();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    const trimmedMessage = message.trim();
    const isInputValid =
      ( trimmedMessage.length > 0 || fileList.length > 0) &&
      (!isMomentEmailValidate || EMAIL_REGEX.test(trimmedMessage));

    setIsInputEnable(isInputValid);
  }, [message, isMomentEmailValidate, fileList]);

  const onDeleteFile =(uid: string)=>{
    const newFileList = fileList.filter((f) => f.uid !== uid);
    setFileList(newFileList);
  }

  return (
    <Container >
      <InputsWrapper isFocused={isFocused}>
      <InputWithIcons>
        <IconsWrapper>
          <IconContainer>
            <UploadFile colorIcon={arrowColor} fileList={fileList} setFileList={setFileList} />
          </IconContainer>
        </IconsWrapper>
        <TextArea 
          placeholder={translation(INPUT_CHAT_TRANSLATIONS.PLACEHOLDER)}
          text={message}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
        />
      </InputWithIcons>
      <ButtonsWrapper>
        <SendButton
          handleClick={onHandleNewMessage}
          isEnabled={isInputEnable && !isDisabled}
          arrowColor={arrowColor}
        />
      </ButtonsWrapper>

      </InputsWrapper>
      {fileList.length > 0 && (
        
      <FilesToSendPreview 
        files={fileList}
        onDelete={onDeleteFile}
      />
      )}
    </Container>
  );
}
