import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import Chat from "./Chat/Chat";
import BubbleButton from "./BubbleButton/BubbleButton";
import { BubbleChatProps } from "./types";
import useGetConfiguration from "../hooks/configuration/useGetConfiguration";
import { Configuration } from "../../domain/adapters/configuration";
import ConfigurationProvider from "../context/configuration/ConfigurationProvider";
import { useGetScraping } from "../hooks/scraping/useGetScraping";

const Container = styled.div`
  z-index: 9999999;
  position: fixed;
  bottom: 20px;
  right: 20px;
  transition: transform 167ms cubic-bezier(0.33, 0, 0, 1) 0s;
  transform-origin: center center;
`;

const COUNT_DEFAULT_UNREAD_MESSAGES = 0;
export default function BubbleChat(bubbleChatProps: BubbleChatProps) {
  const {
    connectionId,
    right,
    bottom,
    openDelay,
    dataFrameBottom,
    dataFrameRight,
    dataInitialOpenChat,
    removeBubbleFixedSize,
    maxChatHeight,
  } = bubbleChatProps;

  if (!connectionId) return;

  const [isOpenChat, setIsOpenChat] = useState(dataInitialOpenChat === null ? false : dataInitialOpenChat);

  const hasOpenedOnce = useRef(false);

  const [configuration, setConfiguration] = useState<Configuration | null>(
    null
  );

  const [unreadMessages, setUnreadMessages] = useState(
    COUNT_DEFAULT_UNREAD_MESSAGES
  );

  const configurationHook = useGetConfiguration({ connectionId });

  const handleNewMessageUnread = () => {
    setUnreadMessages((oldValue) => oldValue + 1);
  };

  const handleResetMessagesUnread = () => {
    setUnreadMessages((_oldValue) => COUNT_DEFAULT_UNREAD_MESSAGES);
  };

  const handleShowChat = () => {
    if (configurationHook.isLoading) return;

    setIsOpenChat((oldValue) => !oldValue);
  };

  useEffect(() => {
    configurationHook.get();
  }, []);

  useEffect(() => {
    if (configurationHook.data) {
      setConfiguration(configurationHook.data);
    }
  }, [configurationHook.data]);

  useEffect(() => {
    if (configurationHook.error) {
      console.warn("WebChat Omuni: Not exist configuration");
    }
  }, [configurationHook.error]);

  useEffect(() => {
    if (!openDelay || hasOpenedOnce.current) return;

    hasOpenedOnce.current = true;

    setTimeout(() => {
      setIsOpenChat(true);
    }, Number(openDelay));
  }, [openDelay]);

  useEffect(() => {
    console.info(
      `Omuni WebChat - Configuration:\nConnection ID: ${connectionId}\nPosition Right: ${right}\nPosition Bottom: ${bottom}\nOpen Delay (ms): ${openDelay}\nData Frame Bottom: ${dataFrameBottom}\nData Frame Right: ${dataFrameRight}\nInitial Open Chat: ${dataInitialOpenChat !== null ? dataInitialOpenChat : 'false'}\nRemove Bubble Fixed Size: ${removeBubbleFixedSize !== null ? removeBubbleFixedSize : 'false'}\nMax Chat Height: ${maxChatHeight || '704px (default)'}`
    );
  }, [connectionId, right, bottom, openDelay, dataInitialOpenChat, removeBubbleFixedSize, maxChatHeight]);

  return (
    <Container>
      {!configurationHook.isLoading && configurationHook.data && (
        <ConfigurationProvider configuration={configurationHook.data}>
          <Chat
            right={right}
            bottom={bottom}
            dataFrameRight={dataFrameRight}
            dataFrameBottom={dataFrameBottom}
            isOpen={isOpenChat}
            connectionId={connectionId}
            handleNewMessageUnread={handleNewMessageUnread}
            handleResetMessagesUnread={handleResetMessagesUnread}
            maxHeight={maxChatHeight}
          />
        </ConfigurationProvider>
      )}

      {!configurationHook.isLoading && configurationHook.data && (
        <BubbleButton
          right={right}
          bottom={bottom}
          hasConfiguration={!!configurationHook.data}
          configuration={configuration}
          unreadMessages={unreadMessages}
          isActive={isOpenChat}
          handleClick={handleShowChat}
          removeBubbleFixedSize={removeBubbleFixedSize}
        />
      )}
    </Container>
  );
}
