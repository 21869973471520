import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FontSize } from "../../utils/Fonts";
import { Button } from "../Button/Button";
import Text from "../Text/Text";
import TextInput from "../TextInput/TextInput";
import { Container } from "./Styled";
import { REGISTER_FORM_TRANSLATIONS, RegisterFormProps } from "./types";

const COLOR_BLACK = "#222";

const BKD_COLOR_BLACK = "#233348";

const BKD_COLOR_DEFAULT = "#b1b1b1";

const REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function RegisterForm(registerFormProps: RegisterFormProps) {
  
  const { handleRegister, backgroundButton } = registerFormProps;

  const { t: translation } = useTranslation();

  const handleRegisterUser = () => {
    handleRegister(nameComplete, email);
  };

  const [nameComplete, setNameComplete] = useState("");

  const [email, setEmail] = useState("");

  const validateEmail = (email: string) => {
    return REGEX.test(String(email).toLowerCase());
  };

  return (
    <Container>
      <Text
        value={translation(REGISTER_FORM_TRANSLATIONS.FORM_TITLE)}
        fontColor={COLOR_BLACK}
        fontSize={FontSize.TEXT_SM}
      />
      <TextInput
        label={translation(REGISTER_FORM_TRANSLATIONS.FULL_NAME)}
        placeholder={translation(
          REGISTER_FORM_TRANSLATIONS.FULL_NAME_PLACEHOLDER
        )}
        handleChange={setNameComplete}
      />
      <TextInput
        label={translation(REGISTER_FORM_TRANSLATIONS.EMAIL)}
        placeholder={translation(REGISTER_FORM_TRANSLATIONS.EMAIL_PLACEHOLDER)}
        handleChange={setEmail}
      />
      <Button
        label={translation(REGISTER_FORM_TRANSLATIONS.START_BUTTON)}
        onClick={handleRegisterUser}
        disabled={!validateEmail(email) || !nameComplete}
        backgroundColor={
          !validateEmail(email) || !nameComplete
            ? BKD_COLOR_DEFAULT
            : backgroundButton ?? BKD_COLOR_BLACK
        }
        width="5rem"
      />
    </Container>
  );
}
